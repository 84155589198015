import { GET, exportFile, POST, POSTBody,FORM ,POSTAny,Download} from '../apiFn';
import url from '../url';
import APIType from './type';

export default {
  /**
   * 添加员工证
   */
  addObjectinfotCard: (params: APIType.addStudentCardParam): Promise<APIType.addStudentCardRes> =>
  POSTBody(url.addObjectinfotCard, params),

  /**
   * 修改员工证
   */
  updateObjectinfo: (params: APIType.addStudentCardParam): Promise<APIType.addStudentCardRes> =>
  POSTBody(url.updateObjectinfo, params),

  /**
   * 员工证导入
   */
  objectinfoImportExcel: (params: APIType.studentCardExcelParam): Promise<APIType.studentCardExcelRes> =>
  FORM(url.objectinfoImportExcel, params),

  /**
   * 员工证批量导入SOS和亲情号码
   */
  importSosAndFamily: (params: APIType.importSosAndFamilyParam): Promise<any> =>
  FORM(url.importSosAndFamily, params),

  /**
   * 员工证批量导入SOS和亲情号码
   */
  importUseStatus: (params: APIType.importUseStatusParam): Promise<any> =>
  FORM(url.importUseStatus, params),

  /**
   * 员工证列表查询
   */
  getObjectinfoTable: (params: APIType.getStudentTableParam): Promise<APIType.getStudentTableRes> =>
  GET(url.getObjectinfoTable, params),

  /**
   * 员工证根据ID查询
   */
  getByIdObjectinfo: (params: APIType.getByIdStudentParam): Promise<APIType.getByIdStudentRes> =>
  GET(url.getByIdObjectinfo, params),


  getByMemberByObjectinfo: (params: APIType.getByObjectIdParam): Promise<APIType.getMemberTableRes> =>
  GET(url.getByMemberByObjectinfo, params),
  
  /**
   * 学习证删除
   */
  deleteObjectinfo: (params: APIType.deleteByIdsParam): Promise<APIType.deleteStudentCardRes> =>
  FORM(url.deleteObjectinfo, params),



  /**
   * 员工证批量导出
   */
  //StudentExportExcel: (params: APIType.exportTableParam): void =>
  //exportFile(url.StudentExportExcel, params),

  StudentExportExcel: (params: APIType.exportTableParam): Promise<any> =>
  Download(url.StudentExportExcel, params),


  /**
   * 员工证分配 (已绑定的无法分配)
   */
  transferStatus: (params: APIType.transferStatusParam): Promise<APIType.transferStatusRes> =>
  POSTBody(url.transferStatus, params),

  /**
   * 员工证批量设置上课免打扰
   */
  batchSendDisturb: (params: APIType.batchSendDisturbParam): Promise<APIType.batchSendDisturbRes> =>
  POSTBody(url.batchSendDisturb, params),

  /**
   * 员工证家长注销
   */
  logoutStudentParent: (params: APIType.logoutStudentParentParam): Promise<APIType.logoutStudentParentRes> =>
  POSTBody(url.logoutStudentParent, params),

  /**
   * 员工证家长解绑
   */
  unboundStudentParent: (params: APIType.logoutStudentParentParam): Promise<APIType.logoutStudentParentRes> =>
  POST(url.unboundStudentParent, params),

    /**
   * 蚂蚁tuid解绑
   */
  unboundStudentMdtid3: (params: APIType.unBindStudentMdtid3Param): Promise<APIType.logoutStudentParentRes> =>
  POST(url.unboundStudentMdtid3, params),
  /**
   * 根据imei号获取cardId
   */
  getByCardId: (params: APIType.getByCardIdParam): Promise<APIType.getByCardIdRes> =>
  GET(url.getByCardId, params),

  /**
   * 重置卡为出厂（会删除对应的卡，需要重新录入）
   */
  resetCard: (params: APIType.getByCardIdParam): Promise<APIType.logoutStudentParentRes> =>
  GET(url.resetCard, params),

  /**
   * 设置员工证备注
   */
  setCardRemark: (params: APIType.setCardRemarkParam): Promise<APIType.addStudentCardRes> =>
  GET(url.setCardRemark, params),

  /**
   * 员工证批量导出智能员工信息、员工卡信息、SOS和亲情号码
   */
  exportStuSosWhiteList: (params: APIType.exportStuSosWhiteListParam): void =>
  exportFile(url.exportStuSosWhiteList, params),

};
