import "core-js/modules/es.array.push.js";
import { reactive, toRefs, defineAsyncComponent, nextTick, computed, watch } from "vue";
import { useStore } from "vuex";
import { useDebounce } from "@/hooks/core/useDebounce";
import API from "@/api/studentCard/index";
import { useMessage } from "@/hooks/web/useMessage";
import { Search } from "@element-plus/icons-vue";
import { ElMessageBox } from "element-plus";
import { formatDate } from '@/utils/formatTime';
export default {
  name: "StudentCard",
  components: {
    IssuedMode: defineAsyncComponent(() => import('@/views/live/IssuedMode.vue')),
    // 自定义组件
    BasicTemplate: defineAsyncComponent(() => import("@/components/BasicTemplateTablePage/index.vue")),
    TreeSelect: defineAsyncComponent(() => import("@/components/TreeSelect/TreeSelect.vue")),
    TypeSelect: defineAsyncComponent(() => import("@/components/TypeSelect/TypeSelect.vue")),
    StudentDistribution: defineAsyncComponent(() => import("./components/StudentDistribution.vue")),
    ImportModal: defineAsyncComponent(() => import("@/components/ImportModal/ImportModal.vue")),
    BotherSet: defineAsyncComponent(() => import("./components/BotherSet.vue")),
    StudentInfo: defineAsyncComponent(() => import("@/components/StudentInfo/StudentInfo.vue")),
    FamilyBinding: defineAsyncComponent(() => import("./components/FamilyBinding.vue")),
    DismissBind: defineAsyncComponent(() => import("./components/DismissBind.vue")),
    Instruction: defineAsyncComponent(() => import("@/components/Instruction")),
    AddStudent: defineAsyncComponent(() => import("./components/AddStudent.vue")),
    PromptBox: defineAsyncComponent(() => import("@/components/PromptBox/index.vue")),
    BatchSearch: defineAsyncComponent(() => import("./components/BatchSearch.vue")),
    AddRemark: defineAsyncComponent(() => import("./components/AddRemark.vue")),
    ExportSelect: defineAsyncComponent(() => import("./components/ExportSelect.vue")),
    RenewalOper: defineAsyncComponent(() => import("../authority/components/RenewalOper.vue"))
  },
  setup() {
    const store = useStore();
    const message = useMessage();
    const refData = reactive({
      renewalOperRefs: null,
      sign: '',
      issuseVisible: false,
      nodeData: null,
      errorList: [],
      errorTitle: '提示',
      refExportSelect: null,
      refAddRemark: null,
      promptBoxRefs: null,
      batchSearchRefs: null,
      AddStudentShow: false,
      isClickTree: false,
      Height: 0,
      basicTemplateRefs: null,
      filterHideRefs: null,
      studentDistributionRefs: null,
      botherSetRefs: null,
      InstructionShow: false,
      activeCardId: null,
      activeInfo: {},
      importModalTitle: "",
      importShow: false,
      botherSetShow: false,
      studentInfoShow: false,
      familyBindingShow: false,
      dismissBindShow: false,
      show: false,
      onoff: false,
      loading: false,
      total: 0,
      current: 1,
      searchForm: {
        schoolId: null,
        childFlag: true,
        holdid: null,
        mdtids: "",
        contactertel: "",
        version: "",
        mdtid3: "",
        objectname: "",
        deviceType: "",
        activationStatus: -1,
        activationTime: [],
        createTime: [],
        bindingStatus: -1,
        useStatus: -1,
        onlineStatus: -1
      },
      activeOrg: {
        id: null,
        name: "",
        activeValue: null
      },
      type: 1,
      tableData: [],
      tableColumns: [{
        type: "selection",
        title: "勾选",
        align: "center",
        key: "checkbox",
        width: 30,
        visible: true
      }, {
        title: "序号",
        align: "center",
        key: "index",
        width: 80,
        slots: {
          customRender: "index"
        },
        visible: true
      }, {
        title: "设备名",
        align: "center",
        sortable: true,
        width: 140,
        key: "objectname",
        visible: true
      }, {
        title: '所属单位',
        align: 'center',
        key: 'holdname',
        visible: true
      }, {
        title: "IMEI",
        align: "center",
        sortable: true,
        width: 140,
        key: "mdtid",
        visible: true
      }, {
        title: "客户设备号",
        width: 140,
        align: "center",
        key: "mdtid3",
        visible: true
      }, {
        title: "设备类型",
        align: "center",
        sortable: true,
        width: 100,
        key: "deviceType",
        visible: true
      }, {
        title: "导入时间",
        width: 160,
        sortable: true,
        align: "center",
        key: "createTime",
        visible: true
      }, {
        title: "激活时间",
        align: "center",
        width: 160,
        key: "activationTime",
        visible: true
      }, {
        title: "备注",
        align: "center",
        width: 160,
        key: "remark",
        slots: {
          customRender: 'remark'
        },
        visible: true
      }, {
        title: "版本",
        align: "center",
        width: 160,
        key: "version",
        visible: true
      }, {
        fixed: "right",
        title: "操作",
        width: 180,
        align: "center",
        key: "opereat",
        slots: {
          customRender: "opereat"
        },
        visible: true
      }],
      activeOrgId: null,
      imeiList: [],
      objectIds: [],
      activeValue: null
    });
    const id = computed(() => store.state.treeData.length ? store.state.treeData[0][0].id : null);
    const userType = computed(() => {
      var _store$state$userInfo;
      return (_store$state$userInfo = store.state.userInfo) === null || _store$state$userInfo === void 0 ? void 0 : _store$state$userInfo.userType;
    });
    const pageSize = computed(() => {
      var _store$state$configDa;
      return store.state.configData[0] ? Number((_store$state$configDa = store.state.configData[0]) === null || _store$state$configDa === void 0 ? void 0 : _store$state$configDa.userConf) : 50;
    }); // table 每页数量过多会导致卡顿
    const isSchool = computed(() => store.state.userInfo ? store.state.userInfo.hasOwnProperty("schoolId") : false);
    //查询
    const onSearch = async () => {
      refData.loading = true;
      const params = {
        childFlag: refData.searchForm.childFlag ? 1 : 0,
        holdid: refData.searchForm.holdid,
        mdtids: refData.searchForm.mdtids,
        contactertel: refData.searchForm.contactertel,
        mdtid3: refData.searchForm.mdtid3,
        objectname: refData.searchForm.objectname,
        version: refData.searchForm.version,
        deviceType: refData.searchForm.deviceType ? refData.searchForm.deviceType : "",
        activationStatus: -1,
        activationBeginTime: refData.searchForm.activationTime && refData.searchForm.activationTime.length ? refData.searchForm.activationTime[0] + " 00:00:00" : "",
        activationEndTime: refData.searchForm.activationTime && refData.searchForm.activationTime.length ? refData.searchForm.activationTime[1] + " 23:59:59" : "",
        createBeginTime: refData.searchForm.createTime && refData.searchForm.createTime.length ? refData.searchForm.createTime[0] + " 00:00:00" : "",
        createEndTime: refData.searchForm.createTime && refData.searchForm.createTime.length ? refData.searchForm.createTime[1] + " 23:59:59" : "",
        bindingStatus: refData.searchForm.bindingStatus,
        useStatus: refData.searchForm.useStatus,
        onlineStatus: refData.searchForm.onlineStatus,
        pageSize: pageSize.value,
        pageNo: refData.current
      };
      try {
        const {
          data,
          msg,
          code
        } = await API.getObjectinfoTable(params);
        refData.tableData = [];
        //console.log("getobjectpage: "+JSON.stringify(data.list)); 
        if (code > 0) {
          message.warning(msg);
        } else {
          refData.total = data.total;
          if (data.list) {
            refData.tableData = data.list.map(item => {
              item.checkbox = false;
              item.createTime = formatDate(item.createTime);
              return item;
            });
            //refData.tableData = Object.keys(data).length?[{...data}]:[];
          }
        }
      } catch (error) {
        //console.log("error" + JSON.stringify(error))
        //message.warning("123");
      } finally {
        refData.loading = false;
      }
    };
    watch(() => id.value, val => {
      nextTick(() => {
        val && (refData.searchForm.holdid = val, debounceOnSearch());
      });
    });
    nextTick(() => {
      refData.searchForm.holdid = store.state.treeData.length ? store.state.treeData[0][0].id : null, debounceOnSearch();
    });
    const [debounceOnSearch] = useDebounce(onSearch, 200);
    const currentChange = val => {
      refData.current = val;
      debounceOnSearch();
    };
    const pageSizeChange = val => {
      debounceOnSearch();
    };
    // 结构树回调
    const handleClick = data => {
      refData.activeOrg.activeValue = data.typeId;
      refData.searchForm.holdid = data.id;
      refData.activeOrg.name = data.name;
      refData.activeOrg.id = data.id;
      refData.searchForm.schoolId = null;
      refData.isClickTree = true;
      debounceOnSearch();
    };
    // 确认导入按钮
    const handleConfirm = async (file, useStatus) => {
      //type : 0为设备库存导入   1为SOS和亲情号码设置  2为禁用解锁设置
      const type = refData.importModalTitle == "接警人设置" ? 1 : refData.importModalTitle == "禁用解锁设置" ? 2 : 0;
      //请求参数
      const params = type == 1 ? {
        file
      } : type == 2 ? {
        file,
        useStatus
      } : type == 0 ? {
        file,
        holdid: refData.searchForm.holdid,
        isUpdate: useStatus
      } : {
        file,
        holdid: refData.searchForm.holdid
      };
      //请求接口
      const url = type == 1 ? "importSosAndFamily" : type == 2 ? "importUseStatus" : "objectinfoImportExcel";
      try {
        refData.loading = true;
        const {
          code,
          msg,
          data
        } = await API[url](params);
        console.log("code" + code + msg);
        if (code == 0) {
          if (data) {
            refData.errorList = type === 0 ? data.hasOwnProperty('failureNames') ? data.failureNames : data : data;
            if (refData.errorList.length > 0) {
              refData.errorTitle = "导入数据错误提示";
              refData.promptBoxRefs.visible = true;
            } else {
              refData.errorList = type === 0 ? data.hasOwnProperty('createNames') ? data.createNames : data : data;
              refData.errorTitle = "导入成功数据";
              if (refData.errorList.length > 0) {
                refData.promptBoxRefs.visible = true;
              }
            }
          }
          debounceOnSearch();
          message.success("上传成功" + data.createNames);
          refData.importShow = false;
        } else {
          message.warning(msg);
        }
      } catch (error) {
        message.error(error.msg);
      } finally {
        refData.loading = false;
      }
    };
    // 打开导入弹框
    const handleShowImprot = (name, type) => {
      if (!refData.searchForm.holdid || !refData.isClickTree) {
        message.warning("请先选择组织");
      } else {
        refData.type = type;
        refData.importModalTitle = name;
        refData.importShow = true;
      }
    };
    //查看设备详情
    const getStudentInfo = async objectid => {
      refData.activeCardId = objectid;
      refData.studentInfoShow = true;
    };
    //删除设备
    const handleDel = async (objectid, name, state) => {
      if (state != 1) {
        ElMessageBox.confirm(`是否确认删除“${name}”该设备`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(async () => {
          const {
            code,
            msg
          } = await API.deleteObjectinfo({
            ids: objectid.toString()
          });
          code == 0 && debounceOnSearch();
          message[code == 0 ? "success" : "warning"](code == 0 ? "删除成功" : msg);
        }).catch(() => {
          // ElMessage.info("已取消退出");
        });
      } else {
        message.warning("该设备已绑定，请先解绑");
        return;
      }
    };
    const [debounceOnDelte] = useDebounce(handleDel, 200);
    // 打开导出弹框
    const handleShowExport = () => {
      refData.refExportSelect.visible = true;
    };
    //清空
    const handleReset = () => {
      const holdid = refData.searchForm.holdid;
      const schoolId = refData.searchForm.schoolId;
      refData.searchForm = {
        childFlag: false,
        holdid,
        schoolId,
        mdtids: "",
        contactertel: "",
        mdtid3: "",
        version: "",
        objectname: "",
        deviceType: "",
        activationStatus: 0,
        activationTime: [],
        createTime: [],
        bindingStatus: 0,
        useStatus: 0,
        onlineStatus: 0
      };
      message.success("查询条件已清空");
    };
    //查询条件显示隐藏
    const handleShowHide = () => {
      refData.onoff = true;
      // refData.basicTemplateRefs.handleInit();
    };
    //设备分配-上课免打扰
    const handleClickType = async type => {
      const dom = type == 1 ? "studentDistributionRefs" : "botherSetRefs";
      const show = type == 1 ? "show" : "botherSetShow";
      refData[show] = true;
    };
    const handleBatchSend = async () => {
      const str = `确认打开批量发送指令“${refData.imeiList}”设备`;
      ElMessageBox.confirm(str, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        refData.issuseVisible = true;
        refData.nodeData = null;
      }).catch(() => {
        // ElMessage.info("已取消退出");
      });
    };
    const handleBatchDelete = async () => {
      const str = `是否确认批量删除“${refData.imeiList}”设备`;
      ElMessageBox.confirm(str, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        const {
          msg,
          code
        } = await API.deleteObjectinfo({
          ids: refData.objectIds.join()
        });
        code == 0 && debounceOnSearch();
        message[code == 0 ? "success" : "warning"](code == 0 ? "删除成功" : msg);
      }).catch(() => {
        // ElMessage.info("已取消退出");
      });
    };
    //勾选table
    const handleSelectionChange = data => {
      refData.imeiList = data.map(item => item.mdtid);
      refData.objectIds = data.map(item => item.objectid);
    };
    //点击修改
    const handleEnit = objectid => {
      refData.activeCardId = objectid;
      refData.AddStudentShow = true;
    };
    //批量查询
    const handleBatch = mdtids => {
      refData.searchForm.mdtids = mdtids;
      debounceOnSearch();
    };
    //批量查询弹框
    const handleShowBatch = () => {
      refData.batchSearchRefs.visible = true;
    };
    //重置
    const handleResetCard = (mdtid, isBinding) => {
      const str = isBinding == 1 ? `“${mdtid}” 设备已绑定，请谨慎操作重置该设备？` : `是否确认重置“${mdtid}”该设备`;
      ElMessageBox.confirm(str, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        const {
          msg,
          code
        } = await API.resetCard({
          mdtid
        });
        code == 0 && debounceOnSearch();
        message[code == 0 ? "success" : "warning"](code == 0 ? "重置成功" : msg);
      }).catch(() => {
        // ElMessage.info("已取消退出");
      });
    };
    //添加备注
    const handleAddRemark = (objectid, remark) => {
      refData.refAddRemark.visible = true;
      refData.refAddRemark.objectid = objectid;
      refData.refAddRemark.remark = remark || '';
    };
    const handleShowForm = (objectid, remark) => {
      refData.activeCardId = objectid;
      refData.AddStudentShow = true;
    };
    //续费
    const handleRenewal = importSize => {
      refData.renewalOperRefs.visible = true;
      refData.renewalOperRefs.cardIds = [];
      for (let i = 0; i < importSize; i++) {
        refData.renewalOperRefs.cardIds.push(i);
      }
      refData.renewalOperRefs.imei = '';
      refData.renewalOperRefs.expireDate = '';
    };
    // 续费导入报错信息
    const handleError = errorList => {
      refData.errorList = errorList;
      refData.promptBoxRefs.visible = true;
    };
    // 初始化函数调用
    return {
      ...toRefs(refData),
      currentChange,
      pageSizeChange,
      debounceOnSearch,
      handleClick,
      handleBatchSend,
      handleBatchDelete,
      handleConfirm,
      handleShowImprot,
      getStudentInfo,
      debounceOnDelte,
      handleReset,
      handleShowForm,
      handleShowHide,
      handleClickType,
      handleSelectionChange,
      handleEnit,
      isSchool,
      pageSize,
      handleBatch,
      handleShowBatch,
      handleResetCard,
      handleAddRemark,
      Search,
      handleShowExport,
      handleError
    };
  }
};