import { defineComponent, toRefs, reactive, computed, watch } from 'vue';
import Eldialog from '@/components/Eldialog/index.vue';
import { useMessage } from '@/hooks/web/useMessage';
import httpApi from "@/api/httpApi";
import { useDebounce } from "@/hooks/core/useDebounce";
export default defineComponent({
  components: {
    Eldialog
  },
  emits: ['handleConfirm', 'update:visible'],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    // 弹框标题
    title: {
      type: String,
      default: ''
    },
    //是否显示状态
    isStatus: {
      type: Boolean,
      default: false
    },
    //是否更新已有数据
    isUpdate: {
      type: Boolean,
      default: false
    },
    // 下载模板
    type: {
      //1,流量卡批量导入模版;2设备批量导入模板;(Integer)
      type: Number,
      default: 1
    }
  },
  setup(props, {
    emit
  }) {
    const message = useMessage();
    const refData = reactive({
      fileData: null,
      useStatus: 1,
      update: 0
    });
    // 初始化表单
    const show = computed({
      get: () => props.visible,
      set: val => {
        emit('update:visible', val);
      }
    });
    watch(() => props.visible, val => {
      val && (refData.fileData = null);
    });
    const handleChange = file => {
      if (!['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(file.raw.type)) {
        message.warning('请上传xlsx/xls格式文档');
        refData.fileData = null;
        return false;
      }
      ;
      refData.fileData = file.raw;
    };
    const confirm = () => {
      if (!refData.fileData) {
        message.warning('请上传文件');
        return false;
      }
      emit('update:visible', false);
      props.isStatus ? emit('handleConfirm', refData.fileData, refData.useStatus) : props.isUpdate ? emit('handleConfirm', refData.fileData, refData.update) : emit('handleConfirm', refData.fileData);
    };
    //下载
    const handleUpload = () => {
      httpApi.downTemplate({
        type: props.type
      });
    };
    const [dounceConfirm] = useDebounce(confirm, 300);
    return {
      ...toRefs(refData),
      show,
      confirm,
      handleUpload,
      handleChange,
      dounceConfirm
    };
  }
});