import { defineComponent, toRefs, reactive } from "vue";
import Eldialog from "@/components/Eldialog/index.vue";
export default defineComponent({
  components: {
    Eldialog
  },
  props: {
    data: {
      type: Array,
      default: []
    },
    errorTitle: {
      type: String,
      default: "'错误提示'"
    }
  },
  setup() {
    const refData = reactive({
      visible: false
    });
    //关闭
    const handleConfirm = () => {
      refData.visible = false;
    };
    return {
      ...toRefs(refData),
      handleConfirm
    };
  }
});