import { defineComponent, toRefs, reactive, computed, watch, defineAsyncComponent, ref } from 'vue';
import Eldialog from '@/components/Eldialog/index.vue';
import { ElSwitch, ElMessageBox, ElRadioGroup, ElRadioButton, ElEmpty, ElScrollbar } from 'element-plus';
import API from '@/api/studentCard/index';
import { useMessage } from '@/hooks/web/useMessage';
export default defineComponent({
  components: {
    Eldialog,
    ElSwitch,
    ElRadioGroup,
    ElRadioButton,
    ElEmpty,
    ElScrollbar,
    TreeSelect: defineAsyncComponent(() => import('@/components/TreeSelect/TreeSelect.vue'))
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    objectid: {
      type: Number,
      default: 1
    }
  },
  emits: ['handleSuccess', 'update:visible'],
  setup(props, {
    emit
  }) {
    const message = useMessage();
    const refData = reactive({
      title: '设备详情',
      info: [{
        name: '设备名：',
        value: '',
        key: 'objectname'
      }, {
        name: '设备型号：',
        value: '',
        key: 'deviceType'
      }, {
        name: 'IMEI号：',
        value: '',
        key: 'mdtid'
      }, {
        name: '客户设备号：',
        value: '',
        key: 'mdtid3'
      }, {
        name: '流量卡号：',
        value: '',
        key: 'simid'
      }, {
        name: 'ICCID：',
        value: '',
        key: 'iccid'
      }, {
        name: '报警号码：',
        value: '',
        key: 'contactertel'
      }, {
        name: '所属组织：',
        value: '',
        key: 'holdname'
      }, {
        name: '硬件版本：',
        value: '',
        key: 'version'
      }],
      dataInfo: {},
      type: 0
    });
    const list = ref([]); // 列表的数据
    watch(() => props.visible, val => {
      val && props.objectid && getStudentInfo(props.objectid);
    });
    // 初始化表单
    const show = computed({
      get: () => props.visible,
      set: val => {
        emit('update:visible', val);
      }
    });
    //查询员工证信息
    const getStudentInfo = async id => {
      refData.type = 0;
      const {
        data,
        code,
        msg
      } = await API.getByIdObjectinfo({
        id
      });
      refData.dataInfo = {
        ...data
      };
      getByMemberByObjectinfo(id);
    };
    const getByMemberByObjectinfo = async objectid => {
      refData.type = 0;
      const {
        data,
        code,
        msg
      } = await API.getByMemberByObjectinfo({
        objectid
      });
      list.value = data;
      console.log("JSON" + JSON.stringify(list.value));
    };
    // 确定按钮
    const confirm = () => {
      emit('update:visible', false);
    };
    const handleUnbindMdtid3 = async (type, mdtid, mdtid3) => {
      ElMessageBox.confirm(`是否确认“${type ? '解绑Tuid' : '注销'}” 设备:${mdtid}  Tuid:${mdtid3}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const url = 'unboundStudentMdtid3';
        const params = {
          mdtid3
        };
        const {
          code,
          msg
        } = await API[url](params);
        message[code == 0 ? 'success' : 'warning'](code == 0 ? `${type ? '解绑' : '注销'}成功` : msg);
        code == 0 && (emit('handleSuccess'), emit('update:visible', false));
      }).catch(() => {
        // ElMessage.info("已取消退出");
      });
    };
    //解绑-注销
    const handleUnbindLogout = async (type, id, mobile, name) => {
      ElMessageBox.confirm(`是否确认“${type ? '解绑' : '注销'}”${name}${mobile}该账号`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const url = 'unboundStudentParent';
        const params = {
          id
        };
        const {
          code,
          msg
        } = await API[url](params);
        message[code == 0 ? 'success' : 'warning'](code == 0 ? `${type ? '解绑' : '注销'}成功` : msg);
        code == 0 && (emit('handleSuccess'), emit('update:visible', false));
      }).catch(() => {
        // ElMessage.info("已取消退出");
      });
    };
    return {
      ...toRefs(refData),
      show,
      list,
      confirm,
      handleUnbindLogout,
      handleUnbindMdtid3
    };
  }
});