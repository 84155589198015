import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-01570a66"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "prompt_box"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Eldialog = _resolveComponent("Eldialog");
  return _openBlock(), _createBlock(_component_Eldialog, _mergeProps(_ctx.$attrs, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = $event => _ctx.visible = $event),
    title: _ctx.errorTitle,
    onConfirmOk: _ctx.handleConfirm,
    width: "480px"
  }), {
    form: _withCtx(() => [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
      return _openBlock(), _createElementBlock("p", {
        key: index
      }, _toDisplayString(item), 1);
    }), 128))])]),
    footer: _withCtx(() => []),
    _: 1
  }, 16, ["visible", "title", "onConfirmOk"]);
}